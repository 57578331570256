import { Grid, GridItem } from 'vant'
import Navbar from '../../common/Navbar.vue'
import '../../../../assets/icon/font-icon.js'
import Share from '../../common/Share.vue'
export default {
    components: {
        Navbar: Navbar,
        Share: Share,
        [Grid.name]: Grid,
        [GridItem.name]: GridItem
    },
    data () {
        return {
            classifys: [],
            page: { current: 0, size: 10 }
        }
    },
    mounted () {
        this.retrieveClassifys()
        this.$refs.share.default('main')
    },
    methods: {
        async retrieveClassifys () {
            var pd = { current: this.page.current, size: this.page.size }
            const { data: res } = await this.$http.post(this.BMS_URL + '/hire/classify/retrieveClassifyPage', this.$qs.stringify(pd))
            if (res.status === '200') {
                this.classifys = res.data
            }
        },
        classifySearch (code) {
            this.$router.push({ path: '/mde/hire/search', query: { classifyCode: code } })
        }
    }
}
