<template>
  <div class="page">
    <Navbar title="全部分类" />
    <van-grid>
      <van-grid-item
        :text="classify.name"
        v-for="classify in classifys"
        :key="classify.code"
        @click="classifySearch(classify.code)"
      >
        <template #icon>
          <svg class="icon" aria-hidden="true">
            <use :xlink:href="classify.icon"></use>
          </svg>
        </template>
      </van-grid-item>
    </van-grid>
    <Share ref="share" />
  </div>
</template>
<script>
import Index from './Index.js'
export default {
  ...Index
}
</script>
<style lang="less" scoped>
@import './Index.less';
</style>
